/* eslint-disable react/jsx-key */
import React, { useState, useCallback } from "react";
import type { IconProps } from "constants/IconConstants";
import { PluginType } from "entities/Action";
import { IconWrapper } from "constants/IconConstants";
import { Popover, Position } from "@blueprintjs/core";
import Entity, { EntityClassNames } from "pages/Editor/Explorer/Entity";
import {
  datasourceTableIcon,
  datasourceColumnIcon,
  namespaceIcon,
  serviceIcon,
  methodIcon,
} from "pages/Editor/Explorer/ExplorerIcons";
import styled from "styled-components";
import DatasourceField from "./DatasourceField";
import type { DatasourceTable, Datasource } from "entities/Datasource";
import { Colors } from "constants/Colors";
import { useCloseMenuOnScroll } from "ee/pages/Editor/Explorer/hooks";
import { SIDEBAR_ID } from "constants/Explorer";
import { INTEGRATION_TABS } from "constants/routes";
import { createNewQueryAction } from "actions/apiPaneActions";
import { hasCreateDatasourceActionPermission } from "ee/utils/permissionHelpers";
import { useDispatch, useSelector } from "react-redux";
import type { AppState } from "ee/reducers";
import { getDatasource, getPlugin } from "ee/selectors/entitiesSelector";
import {
  getPagePermissions,
  getCurrentPageId,
} from "selectors/editorSelectors";
import { importSvg, Toaster, Variant } from "design-system-old";
import type { EventLocation } from "ee/utils/analyticsUtilTypes";
import { Button } from "design-system";
import { DatasourceStructureContext } from "entities/Datasource";

const LightningIcon = importSvg(
  async () => import("assets/icons/control/lightning.svg"),
);

const StyledEntity = styled(Entity)`
  & > div {
    grid-template-columns: 20px auto 1fr auto auto;
  }
`;

interface DatasourceStructureProps {
  dbStructure: any;
  step?: number;
  datasourceId?: string;
}

function DatasourceStructureRegionItem(props: any) {
  const itemData = props.itemData;
  const dispatch = useDispatch();
  const { regionId, regionName } = props;
  // const applicationId = useSelector(getCurrentApplicationId);
  const actions = useSelector((state: AppState) => state.entities.actions);
  const currentPageId = useSelector(getCurrentPageId);
  const datasource: Datasource | undefined = useSelector((state: AppState) =>
    getDatasource(state, props.datasourceId),
  );
  const { plugin } = props;
  const pluginType = plugin?.type;

  const iconProps: IconProps = {
    width: 12,
    height: 12,
  };
  const [active, setActive] = useState(false);
  useCloseMenuOnScroll(SIDEBAR_ID, active, () => setActive(false));
  const canCreateDatasourceActions = true;

  const createQueryAction = useCallback(
    (e, field) => {
      e?.stopPropagation();
      if (currentPageId) {
        if (datasource) {
          dispatch(
            createNewQueryAction(
              currentPageId,
              props.eventFrom as EventLocation,
              datasource?.id,
              { ...field, regionId, regionName },
            ),
          );
        }
      }
    },
    [dispatch, actions, currentPageId, datasource, pluginType],
  );

  const lightningMenu: any = canCreateDatasourceActions
    ? (field: any) => (
        <Button
          className={`button-icon t--template-menu-trigger ${EntityClassNames.CONTEXT_MENU}`}
          isIconButton
          kind="tertiary"
          onClick={(e) => {
            setActive(true);
            createQueryAction(e, { ...field, itemData });
          }}
          startIcon={
            props.context !== DatasourceStructureContext.EXPLORER
              ? "add-line"
              : "increase-control-v2"
          }
        />
      )
    : null;

  // if (dbStructure.templates) templateMenu = lightningMenu;
  const columnsAndKeys: any = itemData.methods || [];
  const serviceAppName = `[${itemData.appName}] ${itemData.serviceName}`;
  return (
    <Entity
      action={() => canCreateDatasourceActions && setActive(!active)}
      active={active}
      className={`datasourceStructure-${
        props.context !== DatasourceStructureContext.EXPLORER &&
        `-${props.context}`
      }`}
      contextMenu={null}
      entityId={`${props.datasourceId}-1-${serviceAppName}-${props.context}`}
      forceExpand={props.forceExpand}
      icon={datasourceColumnIcon}
      isDefaultExpanded={props?.isDefaultOpen}
      name={serviceAppName}
      step={3}
    >
      {columnsAndKeys.map((field: any, index: any) => {
        const _field = {
          name: field.name,
          type: field.returnType.substring(0, 20),
        };
        return (
          <DatasourceField
            extra={lightningMenu(field)}
            field={_field}
            icon={methodIcon}
            key={`${field.name}${index}`}
            step={4}
          />
        );
      })}
    </Entity>
  );
}

export function DatasourceStructure(props: DatasourceStructureProps) {
  const dbStructure = props.dbStructure;

  const [active, setActive] = useState(false);
  useCloseMenuOnScroll(SIDEBAR_ID, active, () => setActive(false));

  const datasource = useSelector((state: AppState) =>
    getDatasource(state, props.datasourceId),
  );

  const plugin = useSelector((state: AppState) =>
    getPlugin(state, datasource?.pluginId ?? ""),
  );

  const datasourcePermissions = datasource?.userPermissions || [];
  const pagePermissions = useSelector(getPagePermissions);

  const canCreateDatasourceActions = hasCreateDatasourceActionPermission([
    ...datasourcePermissions,
    ...pagePermissions,
  ]);

  const serviceAndApp = dbStructure?.services; // 应用加命名空间
  return (
    <Entity
      action={() => canCreateDatasourceActions && setActive(!active)}
      active={active}
      className={`datasourceStructure${
        props.context !== DatasourceStructureContext.EXPLORER &&
        `-${props.context}`
      }`}
      contextMenu={null}
      entityId={`${props.datasourceId}-${dbStructure.regionName}-${props.context}`}
      forceExpand={props.forceExpand}
      icon={datasourceTableIcon}
      isDefaultExpanded={props?.isDefaultOpen}
      name={dbStructure.regionName}
      step={props.step}
    >
      {serviceAndApp.map((field: any, index: any) => {
        return (
          <DatasourceStructureRegionItem
            plugin={plugin}
            {...props}
            itemData={field}
            regionId={dbStructure.regionId}
            regionName={dbStructure.regionName}
          />
        );
      })}
    </Entity>
  );
}

export default DatasourceStructure;
