import React, { useCallback, useMemo, useState, useRef } from "react";
import {
  isRemoteButNeedApiAction,
  PluginPackageName,
  PluginType,
} from "entities/Action";
import styled from "styled-components";
import { Colors } from "constants/Colors";
import {
  Classes,
  IconPositions,
  Toaster,
  Variant,
  Category,
  DialogComponent as Dialog,
  Size,
  Dropdown,
  getTypographyByKey,
  IconSize,
  TooltipComponent as Tooltip,
} from "design-system-old";
import { MenuItem, Button } from "design-system";
import { Button as AButton } from "antd"
import type {
  DropdownOption,
  IconName,
  RenderDropdownOptionType,
} from "design-system-old";
import { Dropdown as DDropdown } from "@design-system/widgets-old";
import { Select, Icon } from "design-system";

import {
  createMessage,
  ERROR_ADD_API_INVALID_URL,
  NEW_API_BUTTON_TEXT,
  NEW_QUERY_BUTTON_TEXT,
} from "ee/constants/messages";
import { getCurrentEnvironmentId } from "ee/selectors/environmentSelectors";
import { getDatasourceStructureById } from "ee/selectors/entitiesSelector";
import { createNewQueryAction } from "actions/apiPaneActions";
import { useDispatch, useSelector } from "react-redux";
import type { AppState } from "ee/reducers";
import { getCurrentPageId } from "selectors/editorSelectors";
import type { Datasource } from "entities/Datasource";
import { refreshDatasourceStructure } from "actions/datasourceActions";
import type { Plugin } from "api/PluginApi";
import type { EventLocation } from "ee/utils/analyticsUtilTypes";
import { noop } from "utils/AppsmithUtils";
import _has from "lodash/has";
import _get from "lodash/get";
import _head from "lodash/head";
import type { DatasourceTable } from "entities/Datasource";
import { getSelectedTableName } from "ee/selectors/entitiesSelector";

export const SelectWrapper = styled.div<{ width?: string }>`
  margin: 10px 0px;
`;

export const Label = styled.p`
  flex: 1;
  ${getTypographyByKey("p1")};
  white-space: nowrap;
`;

export const Bold = styled.span`
  font-weight: 500;
`;

const ActionButton = styled(Button)``;
export const DROPDOWN_DIMENSION = {
  HEIGHT: "36px",
  WIDTH: "404px",
  LARGE_WIDTH: "600px",
};

export type DropdownOptions = Array<DropdownOption>;

export interface DatasourceTableDropdownOption extends DropdownOption {
  data: DatasourceTable;
}

const datasourceIcon: IconName = "tables";
const columnIcon: IconName = "column";
const methodIcon: IconName = "layout-column-line";

export const DEFAULT_DROPDOWN_OPTION = {
  id: "",
  label: "",
  value: "",
  onSelect: () => null,
  data: {},
};

interface NewActionButtonWithModalProps {
  datasource: Datasource;
  disabled?: boolean;
  packageName?: string;
  isLoading?: boolean;
  eventFrom?: string; // this is to track from where the new action is being generated
  plugin?: Plugin;
  style?: any;
  isMenuItem?: boolean;
  title?: string;
  icon?: any;
  successCb?: () => void;
  pluginType?: string;
}
function NewActionButtonWithModal(props: NewActionButtonWithModalProps) {
  const {
    datasource,
    disabled,
    icon,
    isMenuItem,
    plugin,
    style = {},
    successCb,
    title,
  } = props;
  const pluginType = plugin?.type;
  const pluginPackageName = plugin?.packageName;
  const datasourceStructure = useSelector((state: AppState) =>
    getDatasourceStructureById(state, datasource.id),
  );
  const [isSelected, setIsSelected] = useState(false);
  const [isOpen, setSourcePanelVisible] = useState(false);
  const [idLoading, setloading] = useState(false);
  const cref = useRef<HTMLDivElement>(null);

  const [secondTOptions, setSelectedSecondTOptions] = useState<DropdownOptions>(
    [],
  );

  const [thirdTOptions, setSelectedThirdTOptions] = useState<DropdownOptions>(
    [],
  );

  const [selectedTable, selectTable] = useState<DropdownOption>();
  const [selectedColumn, selectColumn] = useState<DropdownOption>();
  const [selectedColumnItem, selectColumnItem] = useState<DropdownOption>();

  const dispatch = useDispatch();
  const actions = useSelector((state: AppState) => state.entities.actions);
  const currentPageId = useSelector(getCurrentPageId);
  const currentEnvironment = useSelector(getCurrentEnvironmentId);
  const queryDefaultTableName = useSelector(getSelectedTableName);

  const dispatchRefresh = useCallback(() => {
    setloading(true);
    dispatch(refreshDatasourceStructure(datasource.id));
    setloading(false);
  }, [dispatch, datasource]);

  const createQueryAction = useCallback(
    (e, field) => {
      e?.stopPropagation();
      if (
        pluginType === PluginType.API &&
        !isRemoteButNeedApiAction(plugin) &&
        (!datasource ||
          !datasource.datasourceStorages[currentEnvironment]
            .datasourceConfiguration ||
          !datasource.datasourceStorages[currentEnvironment]
            .datasourceConfiguration.url)
      ) {
        Toaster.show({
          text: ERROR_ADD_API_INVALID_URL(),
          variant: Variant.danger,
        });
        return;
      }

      if (currentPageId) {
        setIsSelected(true);
        if (datasource) {
          dispatch(
            createNewQueryAction(
              currentPageId,
              props.eventFrom as EventLocation,
              datasource?.id,
              queryDefaultTableName,
              field,
            ),
          );
        }
      }
      if (successCb) {
        successCb();
      }
    },
    [dispatch, actions, currentPageId, datasource, pluginType],
  );

  const handleSubmit = (e: any) => {
    e.stopPropagation();
    let field = {};
    if (initState.cascadeLevel === 2) {
      field = {
        ..._get(selectedColumnItem, "field", {}),
        itemData: _get(selectedColumn, "field", {}),
      };
    } else {
      field = _get(selectedColumn, "field", {});
    }

    createQueryAction(e, field);
  };

  const handleCreateBtn = (e: any) => {
    e.stopPropagation();
    setSourcePanelVisible(true);
    dispatchRefresh();
  };

  const CloseDialog = (e?: any) => {
    e?.stopPropagation();
    setSelectedSecondTOptions([]);
    setSelectedThirdTOptions([]);
    selectTable(undefined);
    selectColumn(undefined);
    selectColumnItem(undefined);
    setSourcePanelVisible(false);
  };

  const onSelectTable = useCallback(
    (table: string | undefined, TableObj: any) => {
      let table2Data: any = [];
      if (table && TableObj) {
        if (initState.cascadeLevel === 2) {
          table2Data = TableObj?.data?.map((column: any) => {
            return {
              id: `${column.appId}_${column.serviceName}`,
              label: <div className="flex gap-2"><Icon color='#ffd301' name={columnIcon} size={"sm"} />{`[${column.appName}] ${column.serviceName}`}</div>,
              value: `${column.appId}_${column.serviceName}`,
              subText: column.serviceType,
              icon: columnIcon,
              iconSize: IconSize.LARGE,
              iconColor: Colors.GOLD,
              field: {
                regionId: TableObj.id,
                regionName: TableObj.label,
                ...column,
              },
            };
          });
        } else {
          table2Data = TableObj?.data?.map((column: any) => {
            return {
              id: `${column.path}_${column.httpMethod}`,
              label: <div className="flex gap-2"><Icon color='#ffd301' name={columnIcon} size={"sm"} />{column.name}</div>,
              value: column.path,
              subText: column.httpMethod,
              icon: columnIcon,
              iconSize: IconSize.LARGE,
              iconColor: Colors.GOLD,
              field: column,
            };
          });
        }
      }
      setSelectedSecondTOptions(table2Data);

      selectTable(TableObj);
      selectColumn(undefined);
    },
    [secondTOptions],
  );

  const onSelectColumn = useCallback(
    (
      table: string | undefined,
      ColumnObj: DropdownOption | undefined,
      level: number,
    ) => {
      if (table && ColumnObj) {
        // AnalyticsUtil.logEvent("GEN_CRUD_PAGE_SELECT_SEARCH_COLUMN");
        if (initState.cascadeLevel === 2) {
          if (level === 1) {
            const _field: any = _get(ColumnObj, "field", {});
            const table3Data: any = _get(_field, "methods", []).map(
              (column: any) => {
                const _path = column.path ? _head(JSON.parse(column.path)) : "";
                return {
                  id: `${_path}-${column.returnType}`,
                  label: <div className="flex gap-2"><Icon color='var(--primary)' name={methodIcon} size={"sm"} />{`${column.name || ""}${_path}`}</div>,
                  value: `${column.name || ""}${_path}`,
                  subText: column.returnType,
                  icon: methodIcon,
                  iconSize: IconSize.LARGE,
                  iconColor: Colors.GOLD,
                  field: {
                    regionId: _field?.regionId,
                    regionName: _field?.regionName,
                    ...column,
                  },
                };
              },
            );
            setSelectedThirdTOptions(table3Data);
            selectColumn(ColumnObj);
            selectColumnItem(undefined);
          } else {
            selectColumnItem(ColumnObj);
          }
        } else if (initState.cascadeLevel === 1) {
          selectColumn(ColumnObj);
        }
      }
    },
    [selectColumn],
  );

  const initState = useMemo(() => {
    let tables = [];
    let newTables: any = [];
    if (isOpen) {
      if (_has(datasourceStructure, "configs")) {
        tables = datasourceStructure?.configs || [];
        newTables = tables.map(({ definitions, title }: any) => ({
          id: title,
          label: <div className="flex gap-2"><Icon color='var(--primary)' name={datasourceIcon} size={"sm"} />{title}</div>,
          value: title,
          icon: datasourceIcon,
          iconSize: IconSize.LARGE,
          iconColor: Colors.BURNING_ORANGE,
          data: definitions,
        }));
      }
      if (_has(datasourceStructure, "regions")) {
        tables = datasourceStructure?.regions || [];
        newTables = tables.map(({ regionId, regionName, services }: any) => ({
          id: regionId,
          label: <div className="flex gap-2"><Icon color='var(--primary)' name={datasourceIcon} size={"sm"} />{regionName}</div>,
          value: regionId,
          icon: datasourceIcon,
          iconSize: IconSize.LARGE,
          iconColor: Colors.BURNING_ORANGE,
          data: services,
        }));
      }
    }

    const cascadeLevel = plugin?.packageName === PluginPackageName.EDAS ? 2 : 1;
    return {
      tables: newTables,
      type: plugin?.packageName,
      cascadeLevel,
      title: {
        first: cascadeLevel === 2 ? "命名空间" : "模块",
        second: cascadeLevel === 2 ? "服务" : "方法",
        third: "方法",
      },
      FORM_WIDTH:
        cascadeLevel === 2
          ? DROPDOWN_DIMENSION.LARGE_WIDTH
          : DROPDOWN_DIMENSION.WIDTH,
    };
  }, [datasourceStructure, plugin?.id, isOpen]);

  return (
    <>
      {isMenuItem ? (
        <MenuItem
          data-testid="t--file-operation"
          id={`file-op-index`}
          key={`file-op-index`}
          onClick={(e: any) => handleCreateBtn(e)}
        >
          <div className="flex gap-2 items-center">
            {icon && <span className="flex-shrink-0">{icon}</span>}
            <span className="overflow-hidden whitespace-nowrap overflow-ellipsis">
              {title}
            </span>
          </div>
        </MenuItem>
      ) : (
        <Button
          className="t--create-query newbtn"
          isDisabled={!!disabled}
          isLoading={isSelected || props.isLoading}
          kind={"primary"}
          onClick={disabled ? noop : handleCreateBtn}
          size="md"
          startIcon="plus"
        >
          {pluginType === PluginType.DB || pluginType === PluginType.SAAS
            ? createMessage(NEW_QUERY_BUTTON_TEXT)
            : createMessage(NEW_API_BUTTON_TEXT)}
        </Button>
      )}
      {/* Dialog for datasource picker */}
      <Dialog
        canOutsideClickClose
        isOpen={isOpen}
        onClose={CloseDialog}
        title={`${pluginType === PluginType.DB || pluginType === PluginType.SAAS
          ? createMessage(NEW_QUERY_BUTTON_TEXT)
          : createMessage(NEW_API_BUTTON_TEXT)
          } - ${datasource.name}`}
        triggerZIndex={1008}
        width={initState.cascadeLevel === 2 ? "700px" : "500px"}
      >
        <div
          className="pb-8 space-y-3 "
          onClick={(e) => e.stopPropagation()}
          ref={cref}
        >
          <SelectWrapper className="space-y-2">
            <Label>
              选择
              <Bold>{initState?.title.first}</Bold>
            </Label>
            <Select
              isLoading={idLoading}
              onSelect={onSelectTable}
              options={initState.tables}
              placeholder="请选择模块"
              value={selectedTable}
            />
          </SelectWrapper>
          <SelectWrapper className="space-y-2" >
            <Label>
              选择
              <Bold>{initState?.title.second}</Bold>
            </Label>
            <Select
              isLoading={idLoading}
              onSelect={(table: string, ColObj: any) =>
                onSelectColumn(table, ColObj, 1)
              }
              options={secondTOptions}
              placeholder="请选择方法"
              value={selectedColumn}
            />
          </SelectWrapper>
          {initState.cascadeLevel === 2 ? (
            <SelectWrapper className="space-y-2" width={initState.FORM_WIDTH}>
              <Label>
                选择
                <Bold>{initState?.title.third}</Bold>
              </Label>
              <Select
                cypressSelector="t--table-dropdown"
                isLoading={idLoading}
                onSelect={(table: string, ColObj: any) =>
                  onSelectColumn(table, ColObj, 2)
                }
                options={thirdTOptions}
                placeholder="请选择方法"
                portalContainer={cref.current}
                value={selectedColumnItem}
                showLabelOnly
                width={initState.FORM_WIDTH}
              />
            </SelectWrapper>
          ) : null}
        </div>

        <div className="">
          <div className="flex items-center justify-end space-x-3">
            <AButton
              className="t--datasource-modal-do-not-save"
              onClick={CloseDialog}
              style={{ borderRadius: "4px" }}
            >
              取消
            </AButton>
            <AButton
              className="t--create-query"
              style={{ borderRadius: "4px" }}
              disabled={
                !_get(selectedTable, "value") ||
                !_get(selectedColumn, "value") ||
                (initState.cascadeLevel === 2 &&
                  !_get(selectedColumnItem, "value"))
              }
              type="primary"
              onClick={handleSubmit}
            // size="sm"
            >
              确认
            </AButton>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default NewActionButtonWithModal;
